<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Executar Algoritmo de Malha"
      subtitle="Selecione a versão da BDGD e CTMTS"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <malha-wizard-tabs
        ref="malhaParametros"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import MalhasService from '@/services/MalhasService';
import MalhaWizardTabs from '@/components/perdas-tecnicas/malhas/MalhaWizardTabs';
import MaterialWizard from '@/components/base/MaterialWizard';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    MalhaWizardTabs,
    MaterialWizard
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'CTMTs', 'Confirmação'],
    loading: false
  }),
  methods: {
    save() {
      const data = this.$refs.malhaParametros.exportData();
      this.loading = true;
      MalhasService.save(data)
        .then(() => {
          this.$toast.success('Execução de malha salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['MALHA']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a execução de malha.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
