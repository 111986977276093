<template>
  <div>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="nome"
            label="Título"
            outlined
            v-model="title"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col
        class="pb-0 mb-10"
        cols="12"
      >
        <div class="text-center display-1 font-weight-normal mb-6">
          Selecione o mês e versão da BDGD
        </div>
        <v-data-table
          v-model="selectedItemLocal"
          :headers="headers"
          :items="items"
          item-key="key"
          :single-select="true"
          show-select
          :loading="loading"
          :hide-default-header="loading"
          :sort-by="['data_registro', 'versao']"
          :sort-desc="['data_registro', 'versao']"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-results-text="Nenhum registro correspondente encontrado"
        >
          <template v-slot:[`item.data_registro`]="{ item }">
            {{ item.data_registro | formatToMonth }}
          </template>
          <template v-slot:[`item.registros`]="{ item }">
            {{ item.registros | parseNumberToIntegerBR }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    titleItem: {
      type: String,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    headers: [
      {
        text: 'Mês',
        value: 'data_registro'
      },
      {
        text: 'Versão',
        value: 'versao'
      },
      {
        text: 'Nº de Linhas',
        value: 'registros'
      }
    ]
  }),
  computed: {
    title: {
      get() {
        return this.titleItem;
      },
      set(newValue) {
        this.$emit('update:titleItem', newValue);
      }
    },
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    }
  },
  methods: {
    stepReleasedIf() {
      if (this.selectedItemLocal.length && this.title !== null) {
        this.$emit('stepReleased', 1);
      } else {
        this.$emit('stepReleased', 0);
      }
    }
  },
  watch: {
    itemsTable() {
      this.items = this.itemsTable.map((item) => {
        item.key = item.data_registro + '-' + item.versao;
        return item;
      });
    },
    selectedItem() {
      this.stepReleasedIf();
    },
    title(val) {
      this.stepReleasedIf();
    }
  }
};
</script>
