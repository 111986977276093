<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemsFirstTab"
        :titleItem.sync="titleItem"
        :loading.sync="loadingFirstTab"
        @stepReleased="$emit('step-observer', { step: 0, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Informe as CTMTS que farão parte da execução
      </div>
      <second-tab-content
        :ctmts="ctmts"
        :flagAllCtmts.sync="flagAllCtmts"
        @update:ctmtFilter="handleCtmtFilterUpdated"
        @stepReleased="
          $emit('step-observer', { step: 1, complete: $event }),
            $emit('step-observer', { step: 2, complete: $event })
        "
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <third-tab-content :records="totalRecordsThirdTab" />
    </v-tab-item>
  </v-container>
</template>

<script>
import CtmtsService from '@/services/CtmtsService';
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import FirstTabContent from '@/components/perdas-tecnicas/malhas/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/perdas-tecnicas/malhas/tabs/SecondTabContent.vue';
import ThirdTabContent from '@/components/perdas-tecnicas/malhas/tabs/ThirdTabContent.vue';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent,
    SecondTabContent,
    ThirdTabContent
  },
  created() {
    this.fillFirstTab();
  },
  data() {
    return {
      arrCtmts: [],
      malha: {},

      //First Tab
      loadingFirstTab: false,
      bdgdVersionId: null,
      itemsTableFirstTab: [],
      titleItem: null,
      selectedItemsFirstTab: [],

      //Second Tab
      ctmts: [],
      ctmtFilter: [],
      flagAllCtmts: false,

      //Third Tab
      totalRecordsThirdTab: 0
    };
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    async fillFirstTab() {
      this.loadingFirstTab = true;
      await ExportacoesBdgdsService.getDatesAndRegistersImportedEntities()
        .then(({ data }) => {
          this.itemsTableFirstTab = data;
          let selectedBdgdVersion = this.itemsTableFirstTab.filter(
            (item) =>
              item.bdgd_versao_id === this.userSelectedCompanyBdgdVersion.id
          );
          this.selectedItemsFirstTab = selectedBdgdVersion;
        })
        .finally(() => {
          this.loadingFirstTab = false;
        });
    },
    fillSecondTab() {
      if (!this.bdgdVersionId) return;
      CtmtsService.getCtmtsByBdgdVersaoId(this.bdgdVersionId)
        .then(({ data }) => {
          this.ctmts = data;
          this.itemsTableSecondTab = data;
          this.flagAllCtmts = false;
        })
        .catch(() =>
          this.$emit('stepReleased', { numberStep: 1, available: false })
        );
    },
    handleCtmtFilterUpdated(newValue) {
      this.ctmtFilter = newValue;
    },
    exportData() {
      return {
        id: null,
        nome: this.titleItem,
        lista_ctmt: JSON.stringify(this.ctmtFilter),
        bdgd_versao_id: this.selectedItemsFirstTab[0].bdgd_versao_id,
        todos_selecionados: this.flagAllCtmts
      };
    }
  },
  watch: {
    selectedItemsFirstTab(arrNewVal) {
      this.bdgdVersionId = arrNewVal.length
        ? arrNewVal[0].bdgd_versao_id
        : null;
      this.fillSecondTab();
    },
    ctmtFilter: {
      handler() {
        let totalRecords = 0;
        totalRecords = this.ctmtFilter.length;
        this.totalRecordsThirdTab = totalRecords;
      },
      deep: true
    }
  }
};
</script>
